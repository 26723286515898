import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const ThankYouPage = ({ location }) => (
	<Layout location={location}>
		<SEO title="Thank you!" description="continuing education" />
		<div class="thank-you-page">
			<div class="container">
				<h1>Thank you!</h1>
				<img
					src={require("@images/thank-you.gif")}
					alt=""
					className="img-fluid my-3"
				/>
				<p className="lead">
					You're all set, and we have everything we need to get started!
					<br />
					Check your email for next steps.
				</p>
				<p className="font-weight-bold">You may now close this window.</p>
			</div>
		</div>
	</Layout>
)

export default ThankYouPage
